import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'


export const getLinksContent = createAsyncThunk('/links/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get('http://127.0.0.1:8000/api/compte/liens-navigation', {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const linksSlice = createSlice({
    name: 'links',
    initialState: {
        isLoading: false,
        links : []
    },
    reducers: {

        addNewLink: (state, action) => {
            let {newLeadObj} = action.payload
            state.links = [...state.links, newLeadObj]
        },

        deleteLink: (state, action) => {
            let {index} = action.payload
            state.links.splice(index, 1)
        },

        sendLinkValide:(state,action)=>{
            let {index} = action.payload
            //state.links.splice(index, 1)
        }


    },

    extraReducers: {
		[getLinksContent.pending]: state => {
			state.isLoading = true
		},
		[getLinksContent.fulfilled]: (state, action) => {
			state.links = action.payload.data;
			state.isLoading = false
		},
		[getLinksContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLink, deleteLink } = linksSlice.actions

export default linksSlice.reducer