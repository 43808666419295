import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import { ENREGISTREMENT_ROLE } from '../../api/ApiUrls';
import { LISTE_ROLES } from '../../api/ApiUrls';


export const getRolesContent = createAsyncThunk('/roles/content', async () => {
    let token=localStorage.getItem("cwpanel");
	const response = await axios.get(LISTE_ROLES, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
	return response.data;
})

export const registerRole = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_ROLE, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
}  

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        isLoading: false,
        isSaving:false,
        error:null,
        roles : [],
        rolesFiltres : [],
        codeResult:600
    },
    reducers: {
        initialiser:(state, action)=>{
            state.isSaving =false
            state.error =null
            state.isSaving=false
            state.codeResult=600
            state.isLoading=false
        },
        registerSuccess: (state, action) => {
            if(action.payload.status==405){
                state.isSaving =false
                state.codeResult=action.payload.status
                state.error =action.payload.errors
            }else if(action.payload.status==201){
                state.isSaving =true
                state.error =null
                state.codeResult=action.payload.status
            }
        },

        addNewLead: (state, action) => {
            let {newLeadObj} = action.payload
            state.roles = [...state.roles, newLeadObj]
        },

        filtrerRoles: (state, action) => {
            let {newLeadObj} = action.payload
            state.roles = [...state.roles, newLeadObj]
        },

        deleteLead: (state, action) => {
            let {index} = action.payload
            state.roles.splice(index, 1)
        }
    },

    extraReducers: {
		[getRolesContent.pending]: state => {
			state.isLoading = true
		},
		[getRolesContent.fulfilled]: (state, action) => {
            
            action.payload.data && action.payload.data.forEach((role,index) => {
                let objet_tampon={}
                objet_tampon.name=role.titre
                objet_tampon.value=role.id
                state.rolesFiltres = [...state.rolesFiltres, objet_tampon]
            });

			state.roles = action.payload.data
			state.isLoading = false
		},
		[getRolesContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLead, deleteLead, registerSuccess, initialiser } = rolesSlice.actions

export default rolesSlice.reducer