import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CONNEXION , CURRENT_USER_CONNECTED_URL, DECONNEXION, INSCRIPTION } from '../../api/ApiUrls';
import axios from 'axios';


export const getUtilisateurconnecteContent = createAsyncThunk('/utilisateurconnecte/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(CURRENT_USER_CONNECTED_URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: null,
    auth_connexion:null,
    connected:false,
    status:"stop",
    lienNavigationSidebar:[],
    sousLienNavigation:[],
    user:{}
  },
  reducers: {
    loginSuccess: (state, action) => {
        state.auth = action.payload;
    },
    logoutSuccess: (state, action) =>  {
      state.auth = action.payload;
    },
    registerSuccess: (state, action) =>  {
      state.auth = action.payload;
    },
    reinitialiserSuccess: (state) =>  {
      state.auth =null;
    },
  },

  extraReducers:(builder)=>{
    builder.addCase(getUtilisateurconnecteContent.pending, (state)=>{
        state.status = 'loading'
    }).addCase(getUtilisateurconnecteContent.fulfilled,(state, action)=>{

        state.user=action.payload.user;

        action.payload.user.role && action.payload.user.role.privileges.forEach((privilege,index) => {
          
          let objet_tampon={}
          if(privilege.lien_navigation){
           
            objet_tampon.code=privilege.lien_navigation.code
            objet_tampon.icon=privilege.lien_navigation.icon
            objet_tampon.titre_fr=privilege.lien_navigation.titre_fr 
            objet_tampon.titre_en=privilege.lien_navigation.titre_en
            objet_tampon.path=privilege.lien_navigation.path

            let estAfficher=false;
            let liste_submenu=[];
            let submenu_objet={};
            privilege.lien_navigation.submenu && privilege.lien_navigation.submenu.forEach((submenu, index)=>{
              if(submenu.affichage=='AA'){
                submenu_objet.code=submenu.code
                submenu_objet.icon=submenu.icon
                submenu_objet.titre_fr=submenu.titre_fr 
                submenu_objet.titre_en=submenu.titre_en
                submenu_objet.path=submenu.path
                submenu_objet.affichage='AA'
                liste_submenu = [...liste_submenu, submenu_objet]
                estAfficher=true;
                submenu_objet={};
              }
            })

            if(estAfficher){
              objet_tampon.submenu=liste_submenu
            }else{
              objet_tampon.submenu=[]
            }
            state.lienNavigationSidebar = [...state.lienNavigationSidebar, objet_tampon]

          }

          let sous_lien_objet_tampon={}
          if(privilege.sous_lien_navigation){
            sous_lien_objet_tampon.path=privilege.sous_lien_navigation.path
            sous_lien_objet_tampon.composant=privilege.sous_lien_navigation.composant
            state.sousLienNavigation = [...state.sousLienNavigation, sous_lien_objet_tampon]
          }

        });

        state.status='looded'
        
    }).addCase(getUtilisateurconnecteContent.rejected, (state)=>{
        state.status = 'error'
    })
}

});
export default authSlice.reducer

// Actions
const { loginSuccess, logoutSuccess, reinitialiserSuccess, registerSuccess} = authSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}

export const signup2 = (payload) => async dispatch => {
  try {
    const response = await axiosClient.put('/utilisateur/creer',payload);
    if(response.data.status==201){
        dispatch(loginSuccess(response.data));
    }else if(response.data.status==404){
        dispatch(loginSuccess(response.data));
    }
  } catch (e) {
    return console.error(e.message);
  }
}

export const signUp3 = (payload) => async dispatch => {
 
  try {
    const response = await axiosClient.put('/utilisateur/login',payload);
    if(response.data.status==200){
        localStorage.setItem("ACCESS_TOKEN",response.data.token);
        dispatch(loginSuccess(response.data));
    }else if(response.data.status==405){
        dispatch(loginSuccess(response.data));
    }
  } catch (e) {
    return console.error(e.message);
  }
}

export const deconnexion = () => async dispatch =>{

  let token=localStorage.getItem("cwpanel");

  const response=await fetch(DECONNEXION, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      },
  }).then(response => response.json())
    .then(
      data => dispatch(logoutSuccess(data))
    )
    .catch(error => console.error(error));
} 

export const signUp = (payload) => async dispatch =>{

  const response=await fetch(CONNEXION, {
      method: "PUT",
      headers: {
          "Content-Type": "application/json",
          "X-API-KEY":"62e9afa9b26ec1658e4f7c572663a19b"
      },
      body: JSON.stringify(payload),
  }).then(response => response.json())
    .then(data => dispatch(loginSuccess(data)))
    .catch(error => console.error(error));

} 

export const signIn = (payload) => async dispatch =>{
  const response=await fetch(INSCRIPTION, {
      method: "PUT",
      headers: {
          "Content-Type": "application/json",
          "X-API-KEY":"62e9afa9b26ec1658e4f7c572663a19b"
      },
      body: JSON.stringify(payload),
  }).then(response => response.json())
    .then(data => dispatch(registerSuccess(data)))
    .catch(error => console.error(error));
}  

export const login = (payload) => async dispatch =>{
  const response=await fetch(CONNEXION, {
      method: "PUT",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
  }).then(response => response.json())
    .then(data => {
      dispatch(registerSuccess(data));
      localStorage.setItem("ACCESS_TOKEN",data.token);
    })
    .catch(error => console.error(error));
} 

export async function getUserConnected(){
  let token=localStorage.getItem("cwpanel");
  const response=await fetch(CURRENT_USER_CONNECTED_URL, {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
      }
  });
  const utilisateur = await response.json();
  return utilisateur;
}  


