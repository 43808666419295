import axiosClient from '../../api/AxiosClient';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CONNEXION , CURRENT_USER_CONNECTED_URL, DECONNEXION, ENREGISTREMENT_PRODUIT, ENREGISTREMENT_ROLE, INSCRIPTION, LISTE_PRODUITS, VOIR_PRODUIT } from '../../api/ApiUrls';
import axios from 'axios';
import { listeRoutesConstants } from '../../constants/listeConstants';


export const getProduitsContent = createAsyncThunk('/produits/content', async () => {
  let token=localStorage.getItem("cwpanel");
  const response = await axios.get(LISTE_PRODUITS, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
  return response.data;
})

export const registerProduit = (payload) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(ENREGISTREMENT_PRODUIT, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => dispatch(registerSuccess(data)))
      .catch(error => console.error(error));
}  

export const montrerProduit = (id) => async dispatch =>{
    let token=localStorage.getItem("cwpanel");
    const response=await fetch(VOIR_PRODUIT+id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => response.json())
      .then(data => dispatch(showSuccess(data)))
      .catch(error => console.error(error));
}  

// Slice
const produitsSlice = createSlice({
  name: 'produit',
  initialState: {
    status:"stop",
    listeCategories:[],
    listeProduitsFiltres:[],
    anneeStockFiltre:null,
    codeResult:600,
    codeResultShow:600,
    isCreate_button:true,
    dateStockInitial:null,
    error:null,
    cles:null,
  },
  reducers: {

    showSuccess: (state, action) =>  {
        if(action.payload.status==405){
            state.codeResultShow = action.payload.status;
        }else if(action.payload.status==200){
            state.codeResultShow = action.payload.status;
        } 
    },
    registerSuccess: (state, action) =>  {
        if(action.payload.status==405){
            state.error = action.payload.errors;
            state.cles = action.payload.cles;
            state.codeResult = action.payload.status;
        }else if(action.payload.status==201){
            state.codeResult = action.payload.status;
        }
    },
    renvoyerListeCategories: (state, action) =>  {

        state.listeCategories =[]
        let categorie_objet_tampon={}
        action.payload.forEach(element => {
            categorie_objet_tampon={}
            categorie_objet_tampon.name=element.libelle
            categorie_objet_tampon.value=element.id
            state.listeCategories = [...state.listeCategories, categorie_objet_tampon]
        });

    },
    creerDateStockinitial:(state,action)=>{
        if(state.isCreate_button){
            state.isCreate_button=false;
        }else{
            state.isCreate_button=true;
        }
        
    },
    ajouterDateStockinitial:(state,action)=>{
        console.log(action.payload)
    },
    reinitialiserSuccess: (state) =>  {
        state.error = null;
        state.cles = null;
        state.codeResult = 600;
        state.status="stop";
        state.codeResultShow=600;
        state.isCreate_button=true
    },
  },

  extraReducers:(builder)=>{
    builder.addCase(getProduitsContent.pending, (state)=>{
        state.status = 'loading'
    }).addCase(getProduitsContent.fulfilled,(state, action)=>{

        state.listeProduitsFiltres =[]

        action.payload.data && action.payload.data.forEach((produit,index) => {

            let objet_tampon={}
            objet_tampon.id=produit.id
            objet_tampon.libelle=produit.libelle
            objet_tampon.description=produit.description
            objet_tampon.code=produit.code
            objet_tampon.mode=produit.mode
            objet_tampon.prix_achat_unitaire=produit.prix_achat_unitaire
            objet_tampon.prix_vente=produit.prix_vente
            objet_tampon.cree_par=produit.cree_par
            objet_tampon.cree_le=produit.cree_le 
            objet_tampon.modifie_par=produit.modifie_par
            objet_tampon.modifie_le=produit.modifie_le

            objet_tampon.marque=produit.marque?produit.marque:null;
            objet_tampon.type_code_barre=produit.type_code_barre?produit.type_code_barre:null;
            objet_tampon.categorie=produit.categorie?produit.categorie:null;
            objet_tampon.unite=produit.unite?produit.unite:null;

            if (state.anneeStockFiltre) {
                produit.annee_stock_quantite && produit.annee_stock_quantite.forEach((item,index) => {
                    if (state.anneeStockFiltre==item.annee_stock_initial) {
                        objet_tampon.annee_stock_quantite=item
                    }
                });
            }else{
                if(produit.annee_stock_quantite){
                    objet_tampon.annee_stock_quantite=produit.annee_stock_quantite[0]
                }
            }

            state.listeProduitsFiltres = [...state.listeProduitsFiltres, objet_tampon]

        });

        state.status='looded'
        
    }).addCase(getProduitsContent.rejected, (state)=>{
        state.status = 'error'
    })
}

});
export default produitsSlice.reducer

// Actions
export const { showSuccess, reinitialiserSuccess, registerSuccess, renvoyerListeCategories, creerDateStockinitial} = produitsSlice.actions

export const reinitialiser = () => dispatch => {
  dispatch(reinitialiserSuccess());
}
 



