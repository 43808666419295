
const NON_ACTIVE=0;
const ACTIVE=1;
const DESACTIVE=2;
const SUPPRIME=3;

//Statut Token
const VALIDE=1;
const EXPIRED=0;

//LOGIN
const CONNECTER=0;
const NONCONNECTER=1;

const RACINE_URL_IMAGE="http://localhost:8000/uploads/images/";

//const RACINE_URL_IMAGE="https://back.prepaconcours-ci.com/uploads/images/";

//Statuts abonnement
//Statut inactif
const INACTIF="inactif";
//Statut actif
const ACTIF="actif";
//Statut depasser
const DEPASSER="depasser";

const USER_CONNECTED="CONNECTED";
const USER_NOTCONNECTED="NOTCONNECTED";
const DATA_LOADING="LOADING";

//Nombre de thèmes disponible si l'utilisateur n'est pas connecte
const NOMBRE_THEME_USER_NONCONNECTE=3;

//Nombre de thèmes disponible si l'utilisateur est connecte
const NOMBRE_THEME_USER_CONNECTE=4;

//Liste des IDS
const NOMBRE_TOTAL_ITEM_BLOCKE=36;
const CODE_ITEM_BLOCKE="NONDISPONIBLE";
const ID_ITEM_BLOCKE=2024;

const VOIRDETAILS="voirdetails";
const ITEMSBLOQUE="itemsbloque";

//DUREE
const DUREEABONNEMENT6MOIS="6mois";
const DUREEABONNEMENT12MOIS="12mois";

//APPARTENANCE
const APPARTENANCE_SURPREPACONCOURS="PREPACONCOURS";
const APPARTENANCE_PAREMAIL="PAREMAIL";
const APPARTENANCE_PARTELEPHONE="PARTELEPHONE";
const NOTIFICATION_INTELLIGENTE="NOTIFICATIONINTELLIGENTE";

export const LISTE_CONSTANTS={NOTIFICATION_INTELLIGENTE,APPARTENANCE_SURPREPACONCOURS,APPARTENANCE_PAREMAIL,APPARTENANCE_PARTELEPHONE,DUREEABONNEMENT6MOIS,DUREEABONNEMENT12MOIS,ITEMSBLOQUE,VOIRDETAILS,CODE_ITEM_BLOCKE,ID_ITEM_BLOCKE,RACINE_URL_IMAGE, INACTIF, ACTIF, DEPASSER, USER_CONNECTED, USER_NOTCONNECTED, NOMBRE_THEME_USER_NONCONNECTE,NOMBRE_THEME_USER_CONNECTE,NOMBRE_TOTAL_ITEM_BLOCKE };


