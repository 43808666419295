//export const baseUrl ="http://localhost:8000/api";

export const baseUrl="https://back.prepaconcours-ci.com/api";

//auth url
export const SIGNUP_URL = `${baseUrl}/auth/signup`;
export const LOGIN_URL = `${baseUrl}/auth/login`;

//current user connected
export const CURRENT_USER_CONNECTED_URL = `${baseUrl}/utilisateur/get-data-utilisateur`;
//Enregistrer un abonnement 
export const ENREGISTREMENT_ABONNEMENT = `${baseUrl}/abonnement/enregistrer-abonnement`;
//Lister offre
export const LISTE_OFFRE = `${baseUrl}/abonnement/liste-offre`;

//INSCRIPTION USER
export const INSCRIPTION = `${baseUrl}/utilisateur/creer`;

//CONNEXION USER
export const CONNEXION = `${baseUrl}/utilisateur/login`;


//DECONNEXION USER
export const DECONNEXION = `${baseUrl}/utilisateur/logout`;

//DECONNEXION USER
export const CURRENT_USER = `${baseUrl}/utilisateur/get-data-utilisateur`;

//Enregistrer un role
export const ENREGISTREMENT_ROLE = `${baseUrl}/role/creer-role`;
export const ENREGISTREMENT_UTILISATEUR = `${baseUrl}/utilisateur/creer`;
export const LISTE_ROLES = `${baseUrl}/role/tous-les-roles`;
export const LISTE_SERVICES = `${baseUrl}/service/tous-les-services`;
export const LISTE_PIECES = `${baseUrl}/nature-piece/toutes-les-pieces`;
export const LISTE_VILLES = `${baseUrl}/ville/toutes-les-villes`;
export const LISTE_PAYS = `${baseUrl}/pays/tous-les-pays`;
export const LISTE_UTILISATEURS = `${baseUrl}/utilisateur/liste`;
export const VOIR_UTILISATEUR = `${baseUrl}/utilisateur/show/`;
export const MISEAJOUR_UTILISATEUR = `${baseUrl}/utilisateur/update`;

//Gestion de Categorie
export const ENREGISTREMENT_CATEGORIE = `${baseUrl}/categorie/creer`;
export const LISTE_CATEGORIES = `${baseUrl}/categorie/toutes-les-categories`;
export const VOIR_CATEGORIE = `${baseUrl}/categorie/details`;
export const MODIFIER_CATEGORIE = `${baseUrl}/categorie/update`;
export const SUPPRIMER_CATEGORIE = `${baseUrl}/categorie/delete/`;

//Gestion produit
export const ENREGISTREMENT_PRODUIT = `${baseUrl}/produit/creer`;
export const LISTE_PRODUITS = `${baseUrl}/produit/tous-les-produits`;
export const VOIR_PRODUIT = `${baseUrl}/produit/show/`;
export const MODIFIER_PRODUIT = `${baseUrl}/produit/update`;
export const SUPPRIMER_PRODUIT = `${baseUrl}/produit/delete/`;

//products url
export const PRODUCTS_URL = `${baseUrl}/products`;

//concours url
export const CONCOURS_URL = `${baseUrl}/concours/show-by-code`;

//epreuve url
export const EPREUVE_URL = `${baseUrl}/epreuve/show-by-code`;

//category url
export const CATEGORIES_URL = `${baseUrl}/categories`;

//cart url
export const CART_URL = `${baseUrl}/user/cart`;

//wishlist url
export const WISHLIST_URL = `${baseUrl}/user/wishlist`;

//Mettre a jour un utilisateur
export const UPDATE_USER_URL = `${baseUrl}/utilisateur/update`; 

//Mettre a jour le mot de passe
export const UPDATE_PASSWORD_URL = `${baseUrl}/utilisateur/update-password`;

//ENVOI DE CODE DE REINITIALISATION
export const ENVOI_CODE_REINITIALISATION_PASSWORD_URL = `${baseUrl}/reinitialisationPassword/mot_de_passe_oublie`;

//VERIFICATION DE CODE DE REINITIALISATION
export const VERIFICATION_CODE_REINITIALISATION_PASSWORD_URL = `${baseUrl}/reinitialisationPassword/verifier_code_generer`;

//Reinitialiser le mot de passe
export const REINITIALISER_PASSWORD_URL = `${baseUrl}/reinitialisationPassword/update_password`;

//Afficher un concours par son code
export const AFFICHER_CONCOURS_PAR_CODE_URL = `${baseUrl}/concours/show-by-code/`;

//Afficher un concours par son code
export const AFFICHER_TOUS_LES_CONCOURS_URL = `${baseUrl}/concours/liste-concours-active`;

//Afficher les concours par leur type
export const AFFICHER_TOUS_LES_CONCOURS_BY_TYPE_URL = `${baseUrl}/concours/liste-concours-active-by-type`;

//Modification notification
export const MODIFICATION_NOTIFICATION_URL = `${baseUrl}/parametres/modifier_notification`;
